import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../pages/Home";
import Preview from "../pages/Preview";
import MapWrapper from "../openlayers/components/MapWrapper";
import NotFound from "../componentsV1/NotFound";
import {
  fetchFilterDataAction,
  fetchFormDataAction,
  getAppVersion,
  getMultipleModelsData,
  refreshUserSessionAction,
  setTableConfigAction,
} from "../pages/Home/action";
import { useDispatch, useSelector } from "react-redux";
import ImpactMap from "../openlayers/components/ImpactMap";
import technical_issue_img from "../assets/images/technical_issues.png";

export const AppRoutingModule = (props) => {
  const [pageConfig, setPageConfig] = useState([]);
  const [techError, setTechError] = useState(false);
  const dispatch = useDispatch();
  const {
    formMasterValuesData,
    filterMenuData,
    isUserSessionActive,
  } = useSelector((state) => state.filter);
  const getConfigData = async () => {
    const pages = await getMultipleModelsData({ model_names: ["tb_pages"] });
    localStorage.setItem("pagesConfig", JSON.stringify(pages["tb_pages"]));
    const version = await getAppVersion({ model_names: ["tb_version"] });
    console.log("CONFIG_PAGES ====>", pages);
    dispatch({
      type: "SET_PAGES_DATA",
      payload: pages,
    });
    // const { tb_version } = pages;
    console.log("VERSION ===>", version);
    // These values coming from Google sheets
    if (!pages?.err) {
      if (formMasterValuesData.length === 0) {
        dispatch(
          fetchFormDataAction({
            model_names: ["tb_form_master", "tb_form_master_values"],
          })
        );
        dispatch(
          setTableConfigAction({
            model_names: ["table_config"],
          })
        );
      }
      if (filterMenuData.length === 0) {
        dispatch(
          fetchFilterDataAction({
            model_names: ["tb_filter_master_copy", "tb_filter_master_values"],
          })
        );
      }
      setPageConfig(pages.tb_pages);
    } else {
      setTechError(true);
    }
  };

  useEffect(async () => {
    const refresh_cache = await dispatch(refreshUserSessionAction({}));
    // getConfigData();
  }, []);
  useEffect(() => {
    if (isUserSessionActive) {
      getConfigData();
      getClientConfigData();
    }
    (() => {
      const isWindows = navigator.userAgent.indexOf("Windows") !== -1;
      if (isWindows) {
        import("../WindowScrollbar.css");
      }
    })();
  }, [isUserSessionActive]);

  const getClientConfigData = async () => {
    const data = await getMultipleModelsData({
      model_names: ["tb_client_config"],
    });
    if (data) {
      dispatch({
        type: "SET_CLIENT_CONFIG",
        payload: data["tb_client_config"],
      });
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      {techError ? (
        <div className="tech-error">
          <img src={technical_issue_img} alt="error" />
        </div>
      ) : (
        <>
          <Router>
            <ScrollToTop />
            <Routes>
              {pageConfig?.map((item) => {
                // Adding routes from google sheets tb_pages
                return (
                  <Route
                    key={item.url}
                    path={`/:client/:product/${item.url}`}
                    element={<Home pageConfig={item} />}
                  />
                );
              })}
              <Route path={`/:client/:product/preview`} element={<Preview />} />
              <Route path={`/not-found`} element={<NotFound />} />
              <Route path={"map"} element={<MapWrapper />} />
              <Route path={"store-map"} element={<ImpactMap />} />
            </Routes>
          </Router>
        </>
      )}
    </div>
  );
};
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
