// req library
import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { FormLabel, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { fetchFormDataAction, getMultipleModelsData } from "../action";

//
import { Tooltip, Button } from "impact-ui";
import { StrategyConfig } from "../strategyConfig";
import { getStorage } from "../../../utils/storage";

// import css
import "../../MainCss.scss";

// import png
import StepFourImg from "../../../assets/images/Img.png";

// Common Component
// import HorizontalCharts from "../CustomComponentsV1/horizontalCharts";
// import VerticalCharts from "../CustomComponentsV1/verticalCharts";

import { default as CustomDatePicker1 } from "../../../componentsV1/CustomDatePicker";
// Calendar Files
import {
  sampleFiscalCalendarData,
  sampleEventList,
  toggleOptions,
  EventList,
  PromoList,
} from "../../../componentsV2/CalendarNew/Constants";
import useAction from "../../../utils/useAction";
import SnackBar from "../../../componentsV2/SnackBar";
const WeekRangePicker = lazy(() =>
  import(
    "../../../componentsV2/CustomWeekRangePicker/CustomWeekRangePicker.jsx"
  )
);
const getDetailContent = lazy(() =>
  import("../../../componentsV2/CalendarNew/Components/GetDetailContent")
);
const moment = lazy(() => import("moment"));
const OptimizationConstraints = lazy(() =>
  import("../optimizationConstraints")
);
const InfoOutlinedIcon = lazy(() => import("@mui/icons-material/InfoOutlined"));
const Typography = lazy(() => import("@mui/material/Typography"));
const SplitTable = lazy(() => import("../SplitTable1"));
const Switch = lazy(() => import("@mui/material/Switch"));
const FormControlLabel = lazy(() => import("@mui/material/FormControlLabel"));
const FormsList = lazy(() => import("../DynamicForms/filtersForms"));

const CalendarEventsColorInfo = lazy(() =>
  import("../../../componentsV2/CalendarNew/Components/CalendarEventsColorInfo")
);
const CustomBadge = lazy(() => import("../CustomComponentsV2/CustomBadge.jsx"));
const Displaygif = lazy(() => import("../CustomComponentsV2/Disaplygif"));
const HtmlContentDisplay = lazy(() =>
  import("../CustomComponentsV2/HtmlContentDisplay")
);
const CommentBox = lazy(() => import("../CustomComponentsV2/CommentBox"));
const CustomDatePicker = lazy(() =>
  import("../../../componentsV2/CustomDatePicker/index")
);
const IATable = lazy(() => import("../../../componentsV2/IA-Table/index"));
const IAInfoPanel = lazy(() =>
  import("../../../componentsV2/InfoPanel/IAInfoPanel")
);
const IconToggle = lazy(() => import("../CustomComponentsV2/IconToggle"));
const IAFilter = lazy(() => import("../../../componentsV2/IAFilters"));
const NestedDropdown = lazy(() =>
  import("../CustomComponentsV2/CustomActionButton/NestedDropdown.jsx")
);
const CustomTag = lazy(() => import("../CustomComponentsV2/CustomTag"));
const CustomPlansmartKPICard = lazy(() =>
  import("../CustomComponentsV2/CustomPlansmartKPICard")
);
const RecurringFreqCalendar = lazy(() =>
  import("../CustomComponentsV2/Products/Marksmart/RecurringFreqCalendar.jsx")
);
const TemporalProvider = lazy(() =>
  import("../CustomComponentsV2/TemporalProvider.jsx")
);
const FigmaView = lazy(() => import("../CustomComponentsV2/FigmaView.jsx"));
const ArcherField = lazy(() =>
  import("../CustomComponentsV2/Archer/index.jsx")
);
const CustomisedDatePicker = lazy(() =>
  import("../CustomComponentsV2/CustomisedDatePicker")
);
// version1 components
const HorizontalTables1 = lazy(() =>
  import("../CustomComponentsV1/HorizontalTables")
);
const VerticleTables1 = lazy(() =>
  import("../CustomComponentsV1/VerticleTables")
);
const HorizontalCharts1 = lazy(() =>
  import("../CustomComponentsV1/HorizontalCharts")
);
const VerticalCharts1 = lazy(() =>
  import("../CustomComponentsV1/VerticalCharts")
);
const CustomisedCard1 = lazy(() =>
  import("../CustomComponentsV1/CustomisedCard")
);
const Steppers1 = lazy(() => import("../../../componentsV1/Steppers/Steppers"));
const Map1 = lazy(() => import("../../../componentsV1/Map"));
const TextFieldWithLabel1 = lazy(() =>
  import("../../../componentsV1/TextField/TextFieldWithLabel")
);
const CustomActionButton1 = lazy(() =>
  import("../CustomComponentsV1/CustomActionButton")
);
const Calendar1 = lazy(() => import("../../../componentsV1/Calendar"));
const KpiCardComponent1 = lazy(() =>
  import("../CustomComponentsV1/KpiCardComponent")
);
const CustomToggle1 = lazy(() => import("../CustomComponentsV1/CustomToggle"));
const ActionKPICard1 = lazy(() =>
  import("../CustomComponentsV1/ActionKPICard")
);
const Container1 = lazy(() => import("../CustomComponentsV1/Container"));
const SelectRenderer1 = lazy(() =>
  import("../../../componentsV1/Select/SelectRenderer")
);
const CustomModal1 = lazy(() => import("../CustomComponentsV1/CustomModal"));
const ActionComponents1 = lazy(() =>
  import("../CustomComponentsV1/ActionComponents")
);
const ButtonDropdown1 = lazy(() =>
  import("../CustomComponentsV1/ButtonDropdown")
);
const CustomRadioButtons1 = lazy(() =>
  import("../CustomComponentsV1/CustomRadioButtons")
);
const CustomTable1 = lazy(() => import("../CustomComponentsV1/CustomTable"));
const InfoPanel1 = lazy(() => import("../../../componentsV1/InfoPanel"));
const CustomDateRange1 = lazy(() =>
  import("../CustomComponentsV1/CustomDateRange")
);
const DownloadFiles1 = lazy(() =>
  import("../CustomComponentsV1/DownloadFiles")
);
const CheckboxGroup1 = lazy(() =>
  import("../../../componentsV1/CheckboxGroup")
);
const TextArea1 = lazy(() => import("../../../componentsV1/TextArea"));
const CustomAccordion1 = lazy(() =>
  import("../../../pages/Home/CustomComponentsV1/CustomAccordian")
);
const VerticalTabs1 = lazy(() => import("../CustomComponentsV1/VerticalTabs"));
const XDView1 = lazy(() => import("../CustomComponentsV1/XDView"));
const CircularProgressBar1 = lazy(() =>
  import("../CustomComponentsV1/CircularProgressBar")
);
const DataLabel1 = lazy(() => import("../CustomComponentsV1/DataLabel"));
const ReportsMap1 = lazy(() => import("../../../componentsV1/ReportsMap"));
const MondayMap2 = lazy(() => import("../../../componentsV2/MondayMap"));
const Treechart1 = lazy(() =>
  import("../../../componentsV1/Charts/formatter/TreeChart")
);
const Filters1 = lazy(() => import("../../../componentsV1/FilterChips"));
const DataChart1 = lazy(() => import("../CustomComponentsV1/DataChart"));
const SimulationResultCards1 = lazy(() =>
  import("../CustomComponentsV1/SimulationResultCards")
);
const CustomImage1 = lazy(() => import("../CustomComponentsV1/CustomImage"));
const CustomIncremntDecremnt1 = lazy(() =>
  import("../CustomComponentsV1/CustomIncremntDecremnt")
);
const CustomCounterButton1 = lazy(() =>
  import("../CustomComponentsV1/CustomCounterButton")
);
const SearchBar1 = lazy(() => import("../CustomComponentsV1/SearchBar"));
const ButtonWithIcon1 = lazy(() =>
  import("../CustomComponentsV1/ButtonWithIcon")
);
const CustomCartersKPICard1 = lazy(() =>
  import("../CustomComponentsV1/CustomCartersKPICard")
);
const CustomSlider1 = lazy(() => import("../CustomComponentsV1/CustomSlider"));
const DragRect1 = lazy(() =>
  import("../../../componentsV1/reactDraggable/draggable")
);
const SplitTableGeneric1 = lazy(() =>
  import("../../Home/CustomComponentsV1/SplitTableGeneric")
);
const FileSelectors1 = lazy(() =>
  import("../../../componentsV1/FileSelectors/index")
);
const ColorScale1 = lazy(() => import("../CustomComponentsV1/ColorScale"));
const CustomMap1 = lazy(() =>
  import("../../../componentsV1/CustomMap/CustomMap")
);
const TimeLine1 = lazy(() => import("../CustomComponentsV1/Timeline"));
const ImageDisplay1 = lazy(() => import("../CustomComponentsV1/ImageDisplay"));
const VisualDisplay1 = lazy(() =>
  import("../CustomComponentsV1/VisualDisplay")
);
const ColorKPICardComponent1 = lazy(() =>
  import("../CustomComponentsV1/ColorKpiCard")
);
const Tabs1 = lazy(() => import("./TabResolverV1"));
const FiltersList1 = lazy(() => import("../FiltersListV1"));
const SplitTable1 = lazy(() => import("../SplitTable1"));
const SelectedRowText1 = lazy(() =>
  import("../CustomComponentsV1/SelectedRowText.jsx")
);
const DateTimePicker1 = lazy(() =>
  import("../../../componentsV1/CustomDateTimePicker")
);

// version2 components
const CustomCard2 = lazy(() => import("../../../componentsV2/CustomCard"));
const HorizontalTables2 = lazy(() =>
  import("../CustomComponentsV2/HorizontalTables")
);
const VerticleTables2 = lazy(() =>
  import("../CustomComponentsV2/VerticleTables")
);
const HorizontalCharts2 = lazy(() =>
  import("../CustomComponentsV2/HorizontalCharts")
);
const VerticalCharts2 = lazy(() =>
  import("../CustomComponentsV2/VerticalCharts")
);
const CustomisedCard2 = lazy(() =>
  import("../CustomComponentsV2/CustomisedCard")
);
const Steppers2 = lazy(() => import("../../../componentsV2/Steppers/Steppers"));
const Map2 = lazy(() => import("../../../componentsV2/Map"));
const TextFieldWithLabel2 = lazy(() =>
  import("../../../componentsV2/TextField/TextFieldWithLabel")
);
const CustomActionButton2 = lazy(() =>
  import("../CustomComponentsV2/CustomActionButton")
);
const Calendar2 = lazy(() =>
  import("../../../componentsV2/CalendarNew/Calendar")
);
const KpiCardComponent2 = lazy(() =>
  import("../CustomComponentsV2/KpiCardComponent")
);
const CustomToggle2 = lazy(() => import("../CustomComponentsV2/CustomToggle"));
const ActionKPICard2 = lazy(() =>
  import("../CustomComponentsV2/ActionKPICard")
);
const Container2 = lazy(() => import("../CustomComponentsV2/Container"));
const CustomDrawer = lazy(() => import("../CustomComponentsV2/CustomDrawer"));
const SelectRenderer2 = lazy(() =>
  import("../../../componentsV2/Select/SelectRenderer")
);
const CustomModal2 = lazy(() => import("../CustomComponentsV2/CustomModal"));
const ActionComponents2 = lazy(() =>
  import("../CustomComponentsV2/ActionComponents")
);
const ButtonDropdown2 = lazy(() =>
  import("../CustomComponentsV2/ButtonDropdown")
);
const CustomRadioButtons2 = lazy(() =>
  import("../CustomComponentsV2/CustomRadioButtons")
);
const CustomTable2 = lazy(() => import("../CustomComponentsV2/CustomTable"));
const InfoPanel2 = lazy(() => import("../../../componentsV2/InfoPanel"));
const CustomDateRange2 = lazy(() =>
  import("../CustomComponentsV2/CustomDateRange")
);
const DownloadFiles2 = lazy(() =>
  import("../CustomComponentsV2/DownloadFiles")
);
const CheckboxGroup2 = lazy(() =>
  import("../../../componentsV2/CheckboxGroup")
);
const TextArea2 = lazy(() => import("../../../componentsV2/TextArea"));
const CustomAccordion2 = lazy(() =>
  import("../../../pages/Home/CustomComponentsV2/CustomAccordian")
);
const VerticalTabs2 = lazy(() => import("../CustomComponentsV2/VerticalTabs"));
const XDView2 = lazy(() => import("../CustomComponentsV2/XDView"));
const CircularProgressBar2 = lazy(() =>
  import("../CustomComponentsV2/CircularProgressBar")
);
const DataLabel2 = lazy(() => import("../CustomComponentsV2/DataLabel"));
const ReportsMap2 = lazy(() => import("../../../componentsV2/ReportsMap"));
const Treechart2 = lazy(() =>
  import("../../../componentsV2/Charts/formatter/TreeChart")
);
const Filters2 = lazy(() => import("../../../componentsV2/FilterChips"));
const DataChart2 = lazy(() => import("../CustomComponentsV2/DataChart"));
const SimulationResultCards2 = lazy(() =>
  import("../CustomComponentsV2/SimulationResultCards")
);
const CustomImage2 = lazy(() => import("../CustomComponentsV2/CustomImage"));
const CustomIncremntDecremnt2 = lazy(() =>
  import("../CustomComponentsV2/CustomIncremntDecremnt")
);
const CustomCounterButton2 = lazy(() =>
  import("../CustomComponentsV2/CustomCounterButton")
);
const SearchBar2 = lazy(() => import("../CustomComponentsV2/SearchBar"));
const ButtonWithIcon2 = lazy(() =>
  import("../CustomComponentsV2/ButtonWithIcon")
);
const CustomCartersKPICard2 = lazy(() =>
  import("../CustomComponentsV2/CustomCartersKPICard")
);
const CustomSlider2 = lazy(() => import("../CustomComponentsV2/CustomSlider"));
const DragRect2 = lazy(() =>
  import("../../../componentsV2/reactDraggable/draggable")
);
const SplitTableGeneric2 = lazy(() =>
  import("../../Home/CustomComponentsV2/SplitTableGeneric")
);
const FileSelectors2 = lazy(() =>
  import("../../../componentsV2/FileSelectors/index")
);
const ColorScale2 = lazy(() => import("../CustomComponentsV2/ColorScale"));
const CustomMap2 = lazy(() =>
  import("../../../componentsV2/CustomMap/CustomMap")
);
const TimeLine2 = lazy(() => import("../CustomComponentsV2/Timeline"));
const ImageDisplay2 = lazy(() => import("../CustomComponentsV2/ImageDisplay"));
const VisualDisplay2 = lazy(() =>
  import("../CustomComponentsV2/VisualDisplay")
);
const ColorKPICardComponent2 = lazy(() =>
  import("../CustomComponentsV2/ColorKpiCard")
);
const Tabs2 = lazy(() => import("./TabResolverV2"));
const FiltersList2 = lazy(() => import("../FiltersListV2"));
const SplitTable2 = lazy(() => import("../SplitTable2"));
const GroceryKPICards2 = lazy(() =>
  import("../CustomComponentsV2/GroceryKPICards")
);
const SwipeableDrawer = lazy(() =>
  import("../CustomComponentsV2/SwipeableDrawer")
);
const CustomLegends = lazy(() => import("../CustomComponentsV2/CustomLegends"));
const CustomTestSmartCard = lazy(() =>
  import("../CustomComponentsV2/CustomTestSmartCard")
);
const NumberGrid = lazy(() => import("../../../componentsV2/NumberGrid"));
const TextContent = lazy(() => import("../CustomComponentsV2/TextContent"));
const PerformanceKPICards = lazy(() =>
  import("../../../componentsV2/CustomPerformanceKPICard")
);
const CustomHorizontalLineWithLabel = lazy(() =>
  import("../CustomComponentsV2/CustomHorizontalLineWithLabel")
);

const CustomHorizontalLine = lazy(() =>
  import("../CustomComponentsV2/CustomHorizontalLine")
);

const CustomVerticalLine = lazy(() =>
  import("../CustomComponentsV2/CustomVerticalLine.jsx")
);

const PerformanceGrowthKPICard = lazy(() =>
  import("../../../componentsV2/CustomPerformanceGrowthKPICard")
);
const PerformanceIndicatorKPICard = lazy(() =>
  import("../../../componentsV2/CustomPerformanceIndicatorKPICard")
);
const CustomPerformanceDisplayKPICard = lazy(() =>
  import("../../../componentsV2/CustomPerformanceDisplayKPICard")
);

const ChatWindow = lazy(() => import("../CustomComponentsV2/ChatWindow"));

const CustomModuleKPICard = lazy(() =>
  import("../../../componentsV2/CustomModuleKPICard")
);

const CustomInvConfigCard = lazy(() =>
  import("../../../componentsV2/CustomInvConfigCard")
);

const CustomInvConfigKpiCard = lazy(() =>
  import("../../../componentsV2/CustomInvConfigKpiCard")
);

const CustomMultiLevelStepper = lazy(() =>
  import("../../../componentsV2/CustomMultiLevelStepper")
);

const CustomMultiLevelStepperV2 = lazy(() =>
  import("../../../componentsV2/CustomMultiLevelStepperV2")
);

const CustomMultiLevelModuleStepper = lazy(() =>
  import("../../../componentsV2/CustomMultiLeveModulelStepper")
);

const CustomPlanConfigCard = lazy(() =>
  import("../../../componentsV2/CustomPlanConfigCard")
);

const CustomColorPicker = lazy(() =>
  import("../../../componentsV2/CustomColorPicker")
);

const KpiMultiColumnCard = lazy(() =>
  import("../../../componentsV2/KpiCardMultiColumn")
);
const FilterMaster = lazy(() => import("../../../componentsV2/FilterMaster"));
const ImageUpload = lazy(() => import("../../../componentsV2/ImageUpload"));
const DragNDrop = lazy(() => import("../../../componentsV2/DragAndDrop"));
const MoodBoard = lazy(() => import("../CustomComponentsV2/MoodBoard"));
const ForecastKpiCard = lazy(() =>
  import("../CustomComponentsV2/ForecastKpiCard")
);
const SelectedRowText2 = lazy(() =>
  import("../CustomComponentsV2/SelectedRowText")
);

const CustomAssortDataCard = lazy(() =>
  import("../CustomComponentsV2/CustomAssortDataCard/CustomAssortDataCard.jsx")
);

const CustomSwitch = lazy(() =>
  import("../CustomComponentsV2/CustomSwitch.jsx")
);
const CustomDropSelectTable = lazy(() =>
  import("../../../componentsV2/CustomDropSelectTable")
);
const CustomDynamicTable = lazy(() =>
  import("../../../componentsV2/CustomDynamicTable")
);
const ExpandableKpiCard = lazy(() =>
  import("../CustomComponentsV2/ExpandableKpiCard")
);

const Counter = lazy(() => import("../CustomComponentsV2/Counters.jsx"));
const CustomColumnsToolPanel = lazy(() =>
  import("../CustomComponentsV2/CustomColumnsToolPanel.jsx")
);
const CustomMetricsToolPanel = lazy(() =>
  import("../CustomComponentsV2/CustomMetricsToolPanel.jsx")
);
const CustomPopover = lazy(() =>
  import("../CustomComponentsV2/CustomPopover.jsx")
);

const BreadCrumbs = lazy(() => import("../../../componentsV2/BreadCrumbs"));

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  filtersHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    float: "right",
    text: "right",
  },
  filtersHeaderTitle: {
    ...theme.typography.fontSizes.sectionHeaderText,
    margin: "0",
    marginRight: 48,
    fontWeight: theme.typography.fontWeight.bold,
  },
});

const useStyles = makeStyles(styles);

const ComponentResolver = (props) => {
  const { pageObject } = props;
  const version = getStorage("APP_VERSION"); // get version values from local storage
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const {
    apiData,
    dependentInfo,
    tableInfo,
    selectedRowInfo,
    selectedDropdownValues,
    columnsInfo,
    formEditedValues,
    displayMessage,
  } = useSelector((state) => state.home);
  const { formMasterValuesData, formMenuData, hideTableKeys } = useSelector(
    (state) => state.filter
  );
  const invokeAction = useAction();
  const [open, setOpen] = useState(false);
  const [componentsList, setComponentsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [defaultChecked, setDefaultChecked] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  // const [pagesInfo, setPagesInfo] = useState(false);

  useEffect(() => {
    if (formMasterValuesData.length === 0) {
      dispatch(
        fetchFormDataAction({
          model_names: ["tb_form_master", "tb_form_master_values"],
        })
      );
    }
    // This will check whether to display table apikey above the table or not
    (async () => {
      try {
        const pagesInfo = JSON.parse(localStorage.getItem("pagesConfig"));
        if (!pagesInfo) {
          let url = window.location.href.split("/");
          url = url[url.length - 1].split("?")[0];
          const presentRoute = pagesInfo["tb_pages"].filter(
            (item) => item?.url === url
          );
          if (presentRoute.length > 0) {
            if (presentRoute[0]?.show_table_keys === "TRUE") {
              dispatch({
                type: "DISPLAY_TABLE_KEYS",
                payload: false,
              });
            } else {
              dispatch({
                type: "DISPLAY_TABLE_KEYS",
                payload: true,
              });
            }
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch({
          type: "DISPLAY_TABLE_KEYS",
          payload: true,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (pageObject["enable_default_key"]) {
      let params = {};
      params[`default-key`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
    if (pageObject["components"]) {
      setComponentsList([...pageObject.components]);
    }
  }, [pageObject]);

  useEffect(() => {
    if (pageObject["defaultEnabledKeys"]) {
      let params = {};
      pageObject["defaultEnabledKeys"].forEach((item) => {
        params = {
          ...params,
          [item]: true,
        };
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
    if (pageObject["defaultDisabledKeys"]) {
      let params = {};
      pageObject["defaultDisabledKeys"].forEach((item) => {
        params = {
          ...params,
          [item]: false,
        };
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  }, []);

  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    setLoader(true);
  };

  const closeLoader = (metric) => {
    setLoader(false);
  };
  const updateState = (params, data) => {
    setSelectedFilters(params);
    if (data.action) {
      switch (data.action.type) {
        case "UPDATE_TABLE_DATA_BASED_ON_SELECTION":
          let tableParams = {};
          tableParams[`${data.action.source_table_key}`] =
            tableInfo[`${data.action.parent_table_key}`];
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
          break;

        default:
          break;
      }
    }
  };
  const appliedFiltersAction = (params, data) => {
    if (data.action && data.action.type === "UPDATE_MASTER_PLAN") {
      let tableParams = {};
      tableParams[`${data.action.source}`] = tableInfo[`${data.action.key}`];
      if (selectedFilters.hasOwnProperty("Plan Version")) {
        const planData = selectedFilters["Plan Version"][0]["label"];
        if (planData === "Working Plan") {
          let reqParams = {};
          reqParams[`${data.filter_id}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: reqParams,
          });
          // dispatch({
          // 	type: "TABLE_DATA",
          // 	payload: tableParams,
          // });
        } else {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.originalPlan}`];
          // dispatch({
          // 	type: "TABLE_DATA",
          // 	payload: tableParams,
          // });
        }
      }
      if (selectedFilters.hasOwnProperty("Channel")) {
        const channelData = selectedFilters["Channel"]
          .map((obj) => obj.label)
          .join("_");
        if (
          data.action.basedOnOptions &&
          data.action.basedOnOptions[`${channelData}`]
        ) {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.basedOnOptions[`${channelData}`]}`];
        }
      }
      if (selectedFilters.hasOwnProperty("Vendors")) {
        const channelData = selectedFilters["Vendors"]
          .map((obj) => obj.label)
          .join("_");
        if (
          data.action.basedOnOptions &&
          data.action.basedOnOptions[`${channelData}`]
        ) {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.basedOnOptions[`${channelData}`]}`];
        }
      }
      dispatch({
        type: "TABLE_DATA",
        payload: tableParams,
      });
    } else if (
      data.action &&
      data.action.type === "FILTER_DATA_BASED_ON_SELECTION" &&
      data.action.filterKeys
    ) {
      let tableData = tableInfo[`${data.action.source}`];
      data.action.filterKeys.forEach((obj) => {
        let category = "",
          field = "";
        if (typeof obj === "string") {
          category = obj;
          field = obj;
        } else {
          category = obj.category;
          field = obj.field;
        }
        let dropdownValues =
          params[`${category}`] && params[`${category}`].length > 0
            ? params[`${category}`].map((valueObj) => valueObj.value)
            : [];
        if (dropdownValues && dropdownValues.length > 0) {
          tableData = tableData.filter((filterObj) => {
            return dropdownValues.indexOf(filterObj[`${field}`]) !== -1;
          });
        }
      });
      let tableParams = {};
      tableParams[`${data.action.destination}`] = tableData;
      dispatch({
        type: "TABLE_DATA",
        payload: tableParams,
      });
    } else if (data?.action && data?.action?.type === "APPLY_FILTER") {
      let dependentParams = {};
      dependentParams[`${data.action.dependentKey}`] =
        !dependentInfo[`${data.action.dependentKey}`];
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: dependentParams,
      });
    }
  };

  const onReset = () => {
    reloadFilters();
  };

  const onLabelActionClick = (data) => {
    switch (data.action_type) {
      case "modal":
        setOpen(!open);

      case "click":
        let params = {};
        params[`${data.name}`] = dependentInfo[`${data.name}`]
          ? !dependentInfo[`${data.name}`]
          : true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      case "RECALCULATE_IA_FORECAST":

      // setModalInfo(data)
      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (params, obj, optionsList) => {
    const selectedOptions = params.selectedItems.map((obj) => obj.label);
    let chartData = [];
    let groupedData = {};
    let selectParams = {};
    let formEditing = {};
    let chartInfo = {};
    switch (obj.actionType) {
      case "AddNewRow":
        const insertedData = [];
        const isDataExisted = tableInfo[`${obj.parent_table_key}`].map(
          (tablObj) => tablObj[`${obj.unique_key}`]
        );
        if (!isDataExisted.includes(params.selectedItems[0]["value"])) {
          tableInfo[`${obj.parent_table_key}`].filter((tableObj, index) => {
            if (index === 0) {
              let reqParams = {};
              Object.keys(tableObj).forEach((nestedObj) => {
                reqParams[`${nestedObj}`] = 0;
              });
              reqParams[`${obj.unique_key}`] = params.selectedItems[0]["value"];
              insertedData.push({ ...reqParams });
            }
          });
          let requestData = {};
          requestData[`${obj.parent_table_key}`] = [
            ...tableInfo[`${obj.parent_table_key}`],
            ...insertedData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: requestData,
          });
        }

        break;
      case "DROPDOWN_EDIT":
        let formEditParams = {};
        formEditParams[`${obj.form_label}`] = params.selectedItems;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditParams,
        });
        break;
      case "OPTION_SELECT":
        let selectionData = {};
        selectionData[obj.dropdown_id] = params.selectedItems;
        dispatch({
          type: "GET_DROPDOWN_SELECTION_DATA",
          payload: selectionData,
        });
        break;
      case "DISPLAY_BASED_ON_SELECTIONS":
        invokeAction(obj.action, { params, optionsList });
        break;
      case "DISPLAY_BASED_ON_OPTIONS":
        const exceptions = new Set(obj?.action?.exceptions);
        const selectedItems = !Array.isArray(params.selectedItems)
          ? [params.selectedItems]
          : params.selectedItems;
        let selectedValues = selectedItems.map((filterObj) => filterObj.value);
        let dropdownParams = {};
        selectedValues = new Set(
          selectedValues.filter((value) => !exceptions.has(value))
        );

        if (optionsList.length > 0) {
          optionsList.forEach((obj) => {
            if (obj?.action?.unique_id) {
              dropdownParams[`${obj?.action?.unique_id}_${obj.value}`] =
                selectedValues.has(obj.value);
            } else {
              dropdownParams[`${obj.value}`] = selectedValues.has(obj.value);
            }
          });
        }
        if (obj?.action?.hideOtherDependents) {
          obj?.action?.hideOtherDependents.forEach((obj) => {
            dropdownParams[`${obj}`] = false;
          });
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: dropdownParams,
        });
        break;
      case "UPDATE_DATA_BASED_ON_SELECTION":
        const selectedValue = params?.selectedItems?.[0]?.value;
        const tableMapping = obj?.action?.source_table_mapping;
        if (tableMapping?.hasOwnProperty(selectedValue)) {
          const sourceData = tableInfo[`${tableMapping[selectedValue]}`];
          if (sourceData !== undefined) {
            dispatch({
              type: "TABLE_DATA",
              payload: {
                [obj?.action?.parent_table_key]: sourceData,
              },
            });
          }
        } else {
          console.error("Table mapping for selected option does not exist");
        }
        break;
      case "ATTRIBUTE_GRADES":
        chartInfo = [...tableInfo[`${obj?.action?.sourceKey}`]];
        chartData = [];
        if (selectedOptions.length === 2) {
          chartInfo = chartInfo.filter(
            (obj) => selectedOptions.indexOf(obj.attribute_type) === -1
          );
        } else {
          chartInfo = chartInfo?.filter(
            (obj) => selectedOptions.indexOf(obj.attribute_type) !== -1
          );
        }

        const AttributeGradeClusters = Array.from(
          {
            length: formEditedValues[`${obj?.action?.destinationKey}_cluster`],
          },
          (_, i) => String.fromCharCode(64 + (i + 1))
        ).concat("Average");
        groupedData = _.groupBy(chartInfo, "name");
        if (Object.keys(groupedData).length > 0) {
          Object.keys(groupedData).forEach((obj) => {
            let params = { ...groupedData[`${obj}`][0] };
            params["data"] = groupedData[`${obj}`]
              .filter(
                (filterObj) =>
                  AttributeGradeClusters.indexOf(filterObj.labels) !== -1
              )
              .map((obj) => parseInt(obj.value));
            chartData.push(params);
          });
        }
        selectParams[`${obj?.action?.destinationKey}`] = chartData;
        formEditing[`${obj?.action?.destinationKey}_grades`] = selectedOptions;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditing,
        });
        dispatch({
          type: "TABLE_DATA",
          payload: selectParams,
        });
        break;
      case "ATTRIBUTE_GRADES_CLUSTERS":
        chartInfo = [...tableInfo[`${obj?.action?.sourceKey}`]];
        chartData = [];
        const clusters = Array.from(
          {
            length: parseInt(selectedOptions[0]),
          },
          (_, i) => String.fromCharCode(64 + (i + 1))
        ).concat("Average");
        chartInfo = chartInfo?.filter(
          (item) =>
            clusters.indexOf(item.labels) !== -1 &&
            formEditedValues[`${obj?.action?.destinationKey}_grades`].indexOf(
              item.attribute_type
            ) !== -1
        );
        groupedData = _.groupBy(chartInfo, "name");
        if (Object.keys(groupedData).length > 0) {
          Object.keys(groupedData).forEach((obj) => {
            let params = { ...groupedData[`${obj}`][0] };
            params["data"] = groupedData[`${obj}`]
              .filter((filterObj) => clusters.indexOf(filterObj.labels) !== -1)
              .map((obj) => parseInt(obj.value));
            chartData.push(params);
          });
        }
        selectParams[`${obj?.action?.destinationKey}`] = chartData;
        formEditing[`${obj?.action?.destinationKey}_cluster`] = selectedOptions;
        formEditing[`${obj?.action?.destinationKey}_chart`] = {
          ...formEditedValues[`${obj?.action?.destinationKey}_chart`],
          xaxis: {
            title:
              formEditedValues[`${obj?.action?.destinationKey}_chart`].xaxis
                .title,
            categories: clusters,
          },
        };
        dispatch({
          type: "TABLE_DATA",
          payload: selectParams,
        });
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditing,
        });
        break;
      case "PERFORMANCE_CLUSTERS_XAXIS":
        chartInfo = [...tableInfo[`${obj?.action?.sourceKey}`]];
        const clusterNames = Array.from(
          {
            length: formEditedValues[`${obj?.action?.destinationKey}_cluster`],
          },
          (_, i) => `Cluster ${i + 1}`
        );
        const yAxis = formEditedValues[`${obj?.action?.destinationKey}_yAxis`];
        chartInfo = chartInfo?.filter(
          (item) =>
            selectedOptions.indexOf(item.xAxis) !== -1 &&
            clusterNames.indexOf(item.name) !== -1 &&
            yAxis.indexOf(item.yAxis) !== -1
        );
        groupedData = _.groupBy(chartInfo, "name");
        if (Object.keys(groupedData).length > 0) {
          Object.keys(groupedData).forEach((obj) => {
            let params = {
              name: groupedData[`${obj}`][0].name,
              color: groupedData[`${obj}`][0].color,
            };
            let clusterValues = [];
            groupedData[`${obj}`].forEach((groupObj) => {
              clusterValues.push([
                parseFloat(groupObj.xAxisValue),
                parseFloat(groupObj.yAxisValue),
              ]);
            });
            params["data"] = clusterValues;
            chartData.push(params);
          });
        }

        selectParams[`${obj?.action?.destinationKey}`] = chartData;
        dispatch({
          type: "TABLE_DATA",
          payload: selectParams,
        });

        formEditing[`${obj?.action?.destinationKey}_xAxis`] = selectedOptions;
        formEditing[`${obj?.action?.destinationKey}_chart`] = {
          ...formEditedValues[`${obj?.action?.destinationKey}_chart`],
          xaxis: {
            title: selectedOptions[0],
            categories: clusterNames,
          },
        };
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditing,
        });

        break;
      case "PERFORMANCE_CLUSTERS_CLUSTERS":
        chartInfo = [...tableInfo[`${obj?.action?.sourceKey}`]];
        let clusterList = Array.from(
          { length: parseInt(selectedOptions[0]) },
          (_, i) => `Cluster ${i + 1}`
        );
        chartInfo = chartInfo?.filter(
          (item) =>
            clusterList.indexOf(item.name) !== -1 &&
            formEditedValues[`${obj?.action?.destinationKey}_xAxis`].indexOf(
              item.xAxis
            ) !== -1 &&
            formEditedValues[`${obj?.action?.destinationKey}_yAxis`].indexOf(
              item.yAxis
            ) !== -1
        );
        groupedData = _.groupBy(chartInfo, "name");
        if (Object.keys(groupedData).length > 0) {
          Object.keys(groupedData).forEach((obj) => {
            let params = {
              name: groupedData[`${obj}`][0].name,
              color: groupedData[`${obj}`][0].color,
            };
            let clusterValues = [];
            groupedData[`${obj}`].forEach((groupObj) => {
              clusterValues.push([
                parseFloat(groupObj.xAxisValue),
                parseFloat(groupObj.yAxisValue),
              ]);
            });
            params["data"] = clusterValues;
            chartData.push(params);
          });
        }

        selectParams[`${obj?.action?.destinationKey}`] = chartData;
        dispatch({
          type: "TABLE_DATA",
          payload: selectParams,
        });

        formEditing[`${obj?.action?.destinationKey}_cluster`] = selectedOptions;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditing,
        });

        break;
      case "PERFORMANCE_CLUSTERS_YAXIS":
        chartInfo = [...tableInfo[`${obj?.action?.sourceKey}`]];
        const clusterNamesList = Array.from(
          {
            length: formEditedValues[`${obj?.action?.destinationKey}_cluster`],
          },
          (_, i) => `Cluster ${i + 1}`
        );
        chartInfo = chartInfo?.filter((item) => {
          return (
            formEditedValues[`${obj?.action?.destinationKey}_xAxis`].indexOf(
              item.xAxis
            ) !== -1 &&
            clusterNamesList.indexOf(item.name) !== -1 &&
            selectedOptions.indexOf(item.yAxis) !== -1
          );
        });
        groupedData = _.groupBy(chartInfo, "name");
        if (Object.keys(groupedData).length > 0) {
          Object.keys(groupedData).forEach((obj) => {
            let params = {
              name: groupedData[`${obj}`][0].name,
              color: groupedData[`${obj}`][0].color,
            };
            let clusterValues = [];
            groupedData[`${obj}`].forEach((groupObj) => {
              clusterValues.push([
                parseFloat(groupObj.xAxisValue),
                parseFloat(groupObj.yAxisValue),
              ]);
            });
            params["data"] = clusterValues;
            chartData.push(params);
          });
        }

        selectParams[`${obj?.action?.destinationKey}`] = chartData;
        dispatch({
          type: "TABLE_DATA",
          payload: selectParams,
        });

        formEditing[`${obj?.action?.destinationKey}_yAxis`] = selectedOptions;
        formEditing[`${obj?.action?.destinationKey}_chart`] = {
          //    ...formEditedValues[`${selectInfo.action.destinationKey}_chart`],
          yaxis: {
            title: selectedOptions[0],
          },
          xaxis: {
            title: selectedOptions[0],
            categories: clusterNamesList,
          },
        };
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: formEditing,
        });
        break;
      case "FILTER_TABLE_DATA_BASED_ON_SELECTION":
        const tableData = tableInfo[`${obj?.tableKey}_copy`];
        const selectedOptionsSet = new Set();
        params?.selectedItems?.forEach((item) =>
          selectedOptionsSet.add(item.value)
        );
        if (tableData) {
          const params = {};
          const updatedTableData = tableData.filter((row) => {
            return selectedOptionsSet.has(row[obj?.field]);
          });
          params[`${obj?.tableKey}`] = updatedTableData;
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      default:
        break;
    }
    let updatedValues = { ...selectedItems };
    updatedValues[`${obj.form_label}`] = params.selectedItems;
    setSelectedItems(updatedValues);
  };
  const onButtonClick = (data) => {
    if (data["button_click"]) {
      let params = {};
      params[`${data.button_name}`] = dependentInfo[`${data.button_name}`]
        ? !dependentInfo[`${data.button_name}`]
        : true;
      params[`${data.button_toggle_other_key}`] = params[`${data.button_name}`]
        ? false
        : true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  };

  const handleCheckboxChange = (e, obj, index) => {
    const { name, checked } = e.target;
    if (obj["isStoreRequired"]) {
      let params = {};
      params[`${name}`] = dependentInfo[`${name}`]
        ? !dependentInfo[`${name}`]
        : true;

      if (obj?.disableKeys) {
        obj?.disableKeys?.forEach((key) => (params[key] = false));
      }

      if (obj?.enableKeys) {
        obj?.enableKeys?.forEach((key) => (params[key] = true));
      }

      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else {
      setSelectedOption({
        index: index,
        dependentOn: name,
      });
    }
    if (obj.hasOwnProperty("action")) {
      invokeAction(obj.action);
    }
  };

  const onAction = (reqObj) => {
    const { key, actionName } = reqObj;
    if (actionName) {
      switch (actionName) {
        case "DEPENDENT":
          let params = {};
          params[`${key}`] = dependentInfo[`${key}`]
            ? !dependentInfo[`${key}`]
            : true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          break;

        default:
          break;
      }
    }
  };
  const handleToggleAction = (action, val) => {
    if (action !== undefined) {
      switch (action?.actionName) {
        case "ENABLE_DISABLE_KEYS":
          let params = {};
          params[action.key] = val;
          params[action.otherKey] = !val;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          break;
        case "FILTER_DATA":
          let filterParams = {};
          const tableData = tableInfo[action.source];

          if (defaultChecked) {
            const updatedData = tableData.filter((obj) => {
              return !action.mappingKey.some((key, index) => {
                // Check if the object's value for the current key is in the corresponding data_key array
                return action.data_key[index].includes(obj[key]);
              });
            });
            filterParams[action.source] = updatedData;
          } else {
            filterParams[action.source] = tableInfo[`${action.source}_copy`];
          }

          dispatch({
            type: "TABLE_DATA",
            payload: filterParams,
          });
          break;
        default:
          return null;
      }
    }
  };

  const handleTextFieldChange = (e, field) => {
    const { name, value } = e.target;
    if (field["isStoreRequired"]) {
      let params = {};
      params[`${name}`] = value;
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    }
  };

  const renderView = (propsObj) => {
    const { data, type, key } = propsObj;
    // selecting component according to UI version
    if (version[0].status == "TRUE") {
      switch (type) {
        case "stepper":
          return <Steppers1 data={data} />;
        case "textField":
          return (
            <>
              {data.info.map((field) => (
                <TextFieldWithLabel1
                  {...field}
                  type={field.type}
                  label={field.label}
                  width={field.width}
                  defaultValue={field.defaultValue}
                />
              ))}
            </>
          );
        case "map":
          return <Map1 info={data} />;
        case "customMap":
          return <CustomMap1 info={data} />;
        case "timeline":
          return <TimeLine1 info={data} />;
        case "search":
          return <SearchBar1 data={data} />;
        case "date-range-picker":
          return <CustomDateRange1 data={data} />;
        case "radio":
          return <CustomRadioButtons1 data={data} />;

        case "KPICard":
          return <KpiCardComponent1 data={data} />;

        case "ActionKPICard":
          return <ActionKPICard1 data={data} />;
        case "ColorKPICard":
          return <ColorKPICardComponent1 data={data} />;
        case "buttonWithIcon":
          return <ButtonWithIcon1 data={data} />;

        case "counter":
          return <CustomIncremntDecremnt1 data={data} />;

        case "draggable":
          return <DragRect1 data={data} />;

        case "card":
          return (
            <Typography gutterBottom variant="h5">
              {data?.title}
              {data.info?.map((item) => (
                <CustomisedCard1 item={item} />
              ))}
            </Typography>
          );
        case "horizontalCharts":
          return <HorizontalCharts1 charts={data.info} {...data} />;
        case "verticalCharts":
          return <VerticalCharts1 charts={data.info} />;
        case "FileSelectors":
          return <FileSelectors1 />;
        case "horizontalTables":
          return <HorizontalTables1 tables={data.info} />;
        case "table":
          return (
            <VerticleTables1
              tables={data.info}
              createExtraColDefs={data.createExtraColDefs}
            />
          );
        case "actionButton":
        case "bottomActionButton":
          return (
            <div style={data.style}>
              <CustomActionButton1 data={data} />
            </div>
          );
        case "buttonDropdown":
          return <ButtonDropdown1 data={data} />;
        case "colorScale":
          return <ColorScale1 data={data} />;
        case "dataChart":
          return <DataChart1 data={data} />;
        case "simulationResultCard":
          return <SimulationResultCards1 data={data} />;
        case "tabs":
          return (
            <Tabs1
              pageObject={{
                tabs: data.info,
                noReset: data.noReset,
                components: data.components,
                redirectionOnClick: data.redirectionOnClick,
                initialSelectedIndex: data.initialSelectedIndex,
                ...data,
              }}
            />
          );
        case "calendar":
          return <Calendar1 onCalEventClick={null} dataObj={data} />;
        case "hr":
          return <hr />;
        case "textContent":
          return <TextContent data={data} />;
        case "filters":
          return (
            <div className={"impact-filterList impact-filterList" + key + type}>
              <FiltersList1
                hideButtons={data.hide_buttons}
                expand={data.expand}
                openLoader={openLoader}
                closeLoader={closeLoader}
                updateParentState={(params) => {
                  updateState(params, data);
                }}
                appliedFilters={(params) => {
                  appliedFiltersAction(params, data);
                }}
                onReset={(params) => {
                  onReset(params, data);
                }}
                screenName={"Filters"}
                isFilterNotRequired={{}}
                filter_id={data.filter_id}
                filter_label={data.filter_label}
                isFilterButtonNotRequired={data.isFilterButtonNotRequired}
                horizontalView={data.horizontalView}
                {...data}
              />
            </div>
          );
        case "forms":
          return (
            <>
              <FormsList
                updateParentState={(params) => {
                  updateState(params, data);
                }}
                appliedFilters={appliedFiltersAction}
                onReset={onReset}
                isSubmitRequired={data.isSubmitRequired}
                form_label={data.form_label}
                isTopFilters={data.isTopFilters}
                filter_id={data.filter_id}
                onLabelActionClick={() => {
                  onLabelActionClick(data);
                }}
                onButtonClick={() => {
                  onButtonClick(data);
                }}
                submitButtonName={data.submitButtonName}
                resetName={data.resetName}
                horizontalView={data.horizontalView}
              />
              {open && data.index === key ? (
                <>
                  <CustomModal1
                    show={open}
                    {...data}
                    handleClose={handleClose}
                  />
                </>
              ) : null}
            </>
          );
        case "select":
          const optionsList = formMasterValuesData.filter((obj) => {
            if (parseInt(obj.parent_id) === data.filter_id) {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          });

          const formDetails =
            formMenuData.filter(
              (obj) => parseInt(obj["categoryid"]) === data.filter_id
            )[0] || {};
          const defaultValue = formMasterValuesData.filter((obj) => {
            if (parseInt(obj.parent_id) === data.filter_id) {
              if (obj.is_default_value === "Y") {
                obj.label = obj.name;
                obj.value = obj.name;
                return obj;
              }
            }
          });
          return (
            <>
              <Grid item md={data.grid || 3}>
                <SelectRenderer1
                  options={optionsList}
                  isMandatory={data.isMandatory}
                  isMulti={
                    data.isFormFilters
                      ? formDetails.type === "single-select"
                        ? false
                        : true
                      : false
                  }
                  width={data.width || ""}
                  filterLabel={
                    data.isFormFilters ? formDetails.label : data.form_label
                  }
                  updateSelected={(params) => {
                    handleSelect(params, data, optionsList);
                  }}
                  selectedItems={
                    selectedItems[`${data.form_label}`] &&
                    selectedItems[`${data.form_label}`].length > 0
                      ? selectedItems[`${data.form_label}`]
                      : defaultValue
                  }
                  {...data}
                />
              </Grid>
            </>
          );
        case "spacer":
          return <div className="content-spacer" style={data.style}></div>;
        case "slider":
          return <CustomSlider1 data={data} />;
        case "modal":
          // return <CustomModal show={open} data={data} />;
          return (
            <CustomModal1
              show={true}
              {...data.action}
              handleClose={handleClose}
            />
          );
        case "actionComponents":
          return <ActionComponents1 data={data} />;
        case "container":
          return <Container1 data={data} />;
        case "toggler":
          return <CustomToggle1 data={data} />;
        case "counterButton":
          return <CustomCounterButton1 data={data} />;
        case "label":
          return (
            <div style={data.style}>
              <label>{data.name} </label>
            </div>
          );
        case "strategry-component":
          return (
            <>
              <StrategyConfig data={data} />
            </>
          );
        case "optimization-constraints":
          return (
            <>
              <OptimizationConstraints />
            </>
          );
        case "horizontal-labels":
          return (
            <>
              <Grid container>
                <Grid item xs={3} sm={3} md={3}>
                  {data.label ? (
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {data.label}
                    </FormLabel>
                  ) : null}
                </Grid>
                {data.options?.map((option) => (
                  <Grid item xs>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {option.label}
                    </FormLabel>{" "}
                    &nbsp;&nbsp;
                    <FormLabel style={{ color: "black" }}>
                      {selectedRowInfo[`${data.parent_table_key}`] &&
                        selectedRowInfo[`${data.parent_table_key}`][0][
                          `${option.mappingKey}`
                        ]}
                    </FormLabel>
                  </Grid>
                ))}
              </Grid>
            </>
          );
        case "image":
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img width="100%" src={StepFourImg} alt="photo" lazy />
            </div>
          );
        case "customImage":
          return <CustomImage1 data={data} />;
        case "info":
          return <InfoPanel1 data={data} />;
        case "split-table":
          return <SplitTable1 tables={data.info} />;

        case "toggle":
          return (
            <>
              {data.startLabel && (
                <span style={{ fontSize: "12px", marginRight: "10px" }}>
                  {data.startLabel}
                </span>
              )}
              <FormControlLabel
                control={<Switch defaultChecked />}
                label={data.label}
              />
            </>
          );
        case "download":
          return <DownloadFiles1 {...data} />;
        case "Checkboxes":
          return (
            <>
              <CheckboxGroup1
                label={data.label}
                options={data.options}
                row={!data.column}
                values={1}
                handleChange={(e) => handleCheckboxChange(e, data, 0)}
              />
            </>
          );
        case "customTable":
          return <CustomTable1 />;
        case "textarea":
          return <TextArea1 data={data} />;
        case "accordian":
          return <CustomAccordion1 data={data} />;
        case "verticalTabs":
          return <VerticalTabs1 data={data} />;
        case "circularProgressBar":
          return <CircularProgressBar1 data={data} />;
        case "dataLabel":
          return <DataLabel1 data={data} onAction={onAction} />;
        case "fileSelector":
          return <FileSelectors1 data={data} />;
        case "reportsMap":
          return <ReportsMap1 info={data} />;
        case "treechart":
          return <Treechart1 charts={data} />;
        case "FilterChips":
          return (
            <>
              {" "}
              <Filters1
                filtersSummary={data.data}
                selectedDropdownValues={selectedDropdownValues}
              />{" "}
            </>
          );
        case "data-definitions":
          return (
            <>
              {data.data.map((obj) => (
                <>
                  <div style={obj.style}>
                    {obj.label ? <>{obj.label} : &nbsp;&nbsp;</> : null}
                    <label>{obj.name} </label>
                  </div>
                </>
              ))}
            </>
          );
        case "grid-view-labels":
          return (
            <>
              <Grid container spacing={1} style={data.style}>
                {data.data.map((obj) => (
                  <>
                    {obj.size ? (
                      <>
                        <Grid item md={obj.size} xl={obj.size} lg={obj.size}>
                          <Typography style={obj.labelStyle}>
                            {obj.label}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs>
                          <Typography style={obj.labelStyle}>
                            {obj.label}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </Grid>
            </>
          );
        case "CartersKPICard":
          return <CustomCartersKPICard1 data={data} />;
        case "split-table-generic":
          return <SplitTableGeneric1 data={data.tables} />;
        case "imageDisplay":
          return <ImageDisplay1 data={data} />;
        case "visualDisplay":
          return <VisualDisplay1 data={data} />;
        case "xdView":
          return <XDView1 data={data} />;
        default:
          return "";
      }

      // version 2
    } else if (version[1].status === "TRUE") {
      switch (type) {
        case "stepper":
          return <Steppers2 data={data} />;
        case "stepperWithComponenets":
          return <Steppers1 data={data} />;
        case "textField":
          return (
            <>
              {data.info.map((field) => (
                <TextFieldWithLabel2
                  {...field}
                  type={field.type}
                  label={field.label}
                  width={field.width}
                  defaultValue={field.defaultValue}
                  onChange={(e) => handleTextFieldChange(e, field)}
                />
              ))}
            </>
          );
        case "map":
          return <Map2 info={data} />;
        case "customMap":
          return <CustomMap2 info={data} />;
        case "timeline":
          return <TimeLine2 info={data} />;
        case "search":
          return <SearchBar2 data={data} />;
        case "date-range-picker":
          return <CustomDateRange2 data={data} />;
        case "radio":
          return <CustomRadioButtons2 data={data} />;

        case "KPICard":
          return <KpiCardComponent2 data={data} />;

        case "KpiMultiColumnCard":
          return <KpiMultiColumnCard data={data} />;

        case "textContent":
          return <TextContent data={data} />;

        case "groceryKPIcards":
          return <GroceryKPICards2 data={data} />;

        case "ActionKPICard":
          return <ActionKPICard2 data={data} />;
        case "ColorKPICard":
          return <ColorKPICardComponent2 data={data} />;
        case "buttonWithIcon":
          return <ButtonWithIcon2 data={data} />;

        case "counter":
          return <CustomIncremntDecremnt2 data={data} />;

        case "draggable":
          return <DragRect2 data={data} />;

        case "card":
          return (
            <Typography gutterBottom variant="h5">
              {data?.title}
              {data.info?.map((item) => (
                <CustomisedCard2 item={item} />
              ))}
            </Typography>
          );
        case "horizontalCharts":
          return <HorizontalCharts2 charts={data.info} {...data} />;
        case "verticalCharts":
          return <VerticalCharts2 charts={data.info} />;
        case "FileSelectors":
          return <FileSelectors2 />;
        case "drawer":
          return <CustomDrawer info={data.info} />;
        case "horizontalTables":
          return <HorizontalTables2 tables={data.info} />;
        case "table":
          return (
            <VerticleTables2
              tables={data.info}
              createExtraColDefs={data.createExtraColDefs}
            />
          );
        case "actionButton":
        case "bottomActionButton":
          return (
            <div style={data.style || data?.buttonWrapperStyle}>
              <CustomActionButton2 data={data} />
            </div>
          );
        case "buttonDropdown":
          return <ButtonDropdown2 data={data} />;
        case "colorScale":
          return <ColorScale2 data={data} />;
        case "dataChart":
          return <DataChart2 data={data} />;
        case "simulationResultCard":
          return <SimulationResultCards2 data={data} />;
        case "tabs":
          return pageObject?.legacyTabs ? (
            <Tabs1
              pageObject={{
                tabs: data.info,
                noReset: data.noReset,
                components: data.components,
                redirectionOnClick: data.redirectionOnClick,
                initialSelectedIndex: data.initialSelectedIndex,
                ...data,
              }}
            />
          ) : (
            <Tabs2
              pageObject={{
                tabs: data.info,
                noReset: data.noReset,
                components: data.components,
                redirectionOnClick: data.redirectionOnClick,
                initialSelectedIndex: data.initialSelectedIndex,
                ...data,
              }}
            />
          );
        case "calendar":
          console.log("calendar ->", data);
          return (
            <>
              <Calendar2
                onCalEventClick={null}
                dataObj={data}
                isLoading={false}
                fiscalCalendar={sampleFiscalCalendarData}
                eventList={
                  data.eventList
                    ? data.eventList
                    : data.for == "event"
                    ? EventList
                    : PromoList
                }
                calendarFrequency={toggleOptions && toggleOptions[1]?.value}
                calMinDate={moment("08/05/2023")}
                calMaxDate={moment("09/22/2023")}
                getDetailContent={getDetailContent}
              />
              <CalendarEventsColorInfo />
            </>
          );
        case "calendar-old":
          return <Calendar1 onCalEventClick={null} dataObj={data} />;
        case "date-range-picker-old":
          return <CustomDateRange1 data={data} />;
        case "hr":
          return <hr />;
        case "filters":
          return (
            <div className={"impact-filterList impact-filterList" + key + type}>
              <FiltersList2
                hideButtons={data.hide_buttons}
                expand={data.expand}
                openLoader={openLoader}
                closeLoader={closeLoader}
                updateParentState={(params) => {
                  updateState(params, data);
                }}
                appliedFilters={(params) => {
                  appliedFiltersAction(params, data);
                }}
                onReset={(params) => {
                  onReset(params, data);
                }}
                screenName={"Filters"}
                isFilterNotRequired={{}}
                filter_id={data.filter_id}
                filter_label={data.filter_label}
                isFilterButtonNotRequired={data.isFilterButtonNotRequired}
                horizontalView={data.horizontalView}
                {...data}
              />
            </div>
          );
        case "filtersMasterSection":
          return (
            <FilterMaster
              title={data.title}
              hideButtons={data.hide_buttons}
              expand={data.expand}
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={(params) => {
                updateState(params, data);
              }}
              appliedFilters={(params) => {
                appliedFiltersAction(params, data);
              }}
              onReset={(params) => {
                onReset(params, data);
              }}
              screenName={"Filters"}
              isFilterNotRequired={{}}
              filter_id={data.filter_id}
              filter_label={data.filter_label}
              isFilterButtonNotRequired={data.isFilterButtonNotRequired}
              horizontalView={data.horizontalView}
              {...data}
            />
          );
        case "forms":
          return (
            <>
              <FormsList
                updateParentState={(params) => {
                  updateState(params, data);
                }}
                appliedFilters={appliedFiltersAction}
                onReset={onReset}
                isSubmitRequired={data.isSubmitRequired}
                form_label={data.form_label}
                isTopFilters={data.isTopFilters}
                filter_id={data.filter_id}
                onLabelActionClick={() => {
                  onLabelActionClick(data);
                }}
                onButtonClick={() => {
                  onButtonClick(data);
                }}
                submitButtonName={data.submitButtonName}
                resetName={data.resetName}
                horizontalView={data.horizontalView}
                gridStyle={data?.gridStyle}
              />
              {open && data.index === key ? (
                <>
                  <CustomModal1
                    show={open}
                    {...data}
                    handleClose={handleClose}
                  />
                </>
              ) : null}
            </>
          );
        case "select":
          const optionsList = formMasterValuesData.filter((obj) => {
            if (parseInt(obj.parent_id) === data.filter_id) {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          });

          const formDetails =
            formMenuData.filter(
              (obj) => parseInt(obj["categoryid"]) === data.filter_id
            )[0] || {};
          const defaultValue = formMasterValuesData.filter((obj) => {
            if (parseInt(obj.parent_id) === data.filter_id) {
              if (obj.is_default_value === "Y") {
                obj.label = obj.name;
                obj.value = obj.name;
                return obj;
              }
            }
          });
          let uniqueOptions = [];
          if (data?.optionsFromSheet) {
            const tableData = tableInfo[`${data.tableKey}_copy`];
            const uniqueValueFromSheet = new Set();
            tableData?.forEach((item) => {
              uniqueValueFromSheet.add(item[data.field]);
            });
            uniqueOptions = Array.from(uniqueValueFromSheet).map((item) => {
              return { label: item, value: item };
            });
          }
          return (
            <>
              <Grid item md={data.grid || 12} style={{ ...data?.gridStyle }}>
                <SelectRenderer2
                  enableSelectAll={
                    formDetails?.enableSelectAll === "TRUE" ? true : false
                  }
                  options={
                    (data?.optionsFromSheet && uniqueOptions) || optionsList
                  }
                  isMandatory={data.isMandatory}
                  isMulti={
                    data.isFormFilters
                      ? formDetails.type === "single-select"
                        ? false
                        : true
                      : false
                  }
                  width={data.width || ""}
                  filterLabel={
                    data.isFormFilters ? formDetails.label : data.form_label
                  }
                  updateSelected={(params) => {
                    const optionList =
                      (data?.optionsFromSheet && uniqueOptions) || optionsList;
                    handleSelect(params, data, optionList);
                  }}
                  selectedItems={
                    selectedItems[`${data.form_label}`] &&
                    selectedItems[`${data.form_label}`].length > 0
                      ? selectedItems[`${data.form_label}`]
                      : data.optionsFromSheet
                      ? data?.defaultSelectAll
                        ? uniqueOptions
                        : data?.defaultSelectedItems
                      : defaultValue
                  }
                  {...data}
                />
              </Grid>
            </>
          );
        case "spacer":
          return (
            <div
              className="content-spacer"
              style={{ marginTop: data?.gap, ...data?.style }}
            ></div>
          );
        case "slider":
          return <CustomSlider2 data={data} />;
        case "modal":
          // return <CustomModal show={open} data={data} />;
          return (
            <CustomModal2
              show={true}
              {...data.action}
              handleClose={handleClose}
            />
          );
        case "actionComponents":
          return <ActionComponents2 data={data} />;
        case "container":
          return <Container2 data={data} />;
        case "toggler":
          return <CustomToggle2 data={data} />;
        case "counterButton":
          return <CustomCounterButton2 data={data} />;
        case "label":
          return (
            <div className="label-component" style={{ ...data.style }}>
              <label>{data.name} </label>
              {data.tooltip ? (
                <Tooltip text={data.label} placement={data.placement}>
                  <Button variant="url" icon={InfoOutlinedIcon} />
                </Tooltip>
              ) : null}
            </div>
          );
        case "strategry-component":
          return (
            <>
              <StrategyConfig data={data} />
            </>
          );
        case "optimization-constraints":
          return (
            <>
              <OptimizationConstraints />
            </>
          );
        case "horizontal-labels":
          return (
            <>
              <Grid container>
                <Grid item xs={3} sm={3} md={3}>
                  {data.label ? (
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {data.label}
                    </FormLabel>
                  ) : null}
                </Grid>
                {data.options?.map((option) => (
                  <Grid item xs>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {option.label}
                    </FormLabel>{" "}
                    &nbsp;&nbsp;
                    <FormLabel style={{ color: "black" }}>
                      {selectedRowInfo[`${data.parent_table_key}`] &&
                        selectedRowInfo[`${data.parent_table_key}`][0][
                          `${option.mappingKey}`
                        ]}
                    </FormLabel>
                  </Grid>
                ))}
              </Grid>
            </>
          );
        case "image":
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img width="100%" src={StepFourImg} alt="photo" />
            </div>
          );
        case "customImage":
          return <CustomImage2 data={data} />;
        case "info":
          return <InfoPanel2 data={data} />;
        case "split-table":
          return <SplitTable2 tables={data.info} />;

        case "toggle":
          if (firstRender) {
            data?.defaultChecked === false
              ? setDefaultChecked(false)
              : setDefaultChecked(true);
            setFirstRender(false);
          }
          return (
            <>
              {data.startLabel && (
                <span style={{ fontSize: "14px", marginRight: "10px" }}>
                  {data.startLabel}
                </span>
              )}
              <FormControlLabel
                control={<Switch checked={defaultChecked} />}
                label={data.label}
                sx={data.style}
                disabled={data?.disabled || false}
                onChange={(e) => {
                  setDefaultChecked(!defaultChecked);
                  if (Array.isArray(data?.action)) {
                    data.action.forEach((action) =>
                      handleToggleAction(
                        action,
                        e.target.checked,
                        defaultChecked
                      )
                    );
                  } else {
                    handleToggleAction(
                      data?.action,
                      e.target.checked,
                      defaultChecked
                    );
                  }
                }}
              />
            </>
          );
        case "download":
          return <DownloadFiles2 {...data} />;
        case "Checkboxes":
          return (
            <>
              <CheckboxGroup2
                label={data.label}
                options={data.options}
                row={!data.column}
                values={1}
                style={data.style}
                handleChange={(e) => handleCheckboxChange(e, data, 0)}
              />
            </>
          );
        case "customTable":
          return <CustomTable2 />;
        case "textarea":
          return <TextArea2 data={data} />;
        case "accordian":
          return <CustomAccordion2 data={data} />;
        case "verticalTabs":
          return <VerticalTabs2 data={data} />;
        case "circularProgressBar":
          return <CircularProgressBar2 data={data} />;
        case "dataLabel":
          return <DataLabel2 data={data} onAction={onAction} />;
        case "fileSelector":
          return <FileSelectors2 data={data} />;
        case "reportsMap":
          return <ReportsMap2 info={data} />;
        case "mondayMap":
          return <MondayMap2 info={data} />;
        case "treechart":
          return <Treechart2 charts={data} />;
        case "FilterChips":
          return (
            <>
              {" "}
              <Filters2
                filtersSummary={data.data}
                selectedDropdownValues={selectedDropdownValues}
              />{" "}
            </>
          );
        case "data-definitions":
          return (
            <>
              {data.data.map((obj) => (
                <>
                  <div style={obj.style}>
                    {obj.label ? <>{obj.label} : &nbsp;&nbsp;</> : null}
                    <label>{obj.name} </label>
                  </div>
                </>
              ))}
            </>
          );
        case "grid-view-labels":
          return (
            <>
              <Grid container spacing={1} style={data.style}>
                {data.data.map((obj) => (
                  <>
                    {obj.size ? (
                      <>
                        <Grid item md={obj.size} xl={obj.size} lg={obj.size}>
                          <Typography style={obj.labelStyle}>
                            {obj.label}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs>
                          <Typography style={obj.labelStyle}>
                            {obj.label}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </Grid>
            </>
          );
        case "CartersKPICard":
          return <CustomCartersKPICard2 data={data} />;
        case "TestSmartCard":
          return <CustomTestSmartCard data={data} />;
        case "split-table-generic":
          return <SplitTableGeneric2 data={data.tables} />;
        case "imageDisplay":
          return <ImageDisplay2 data={data} />;
        case "visualDisplay":
          return <VisualDisplay2 data={data} />;
        case "xdView":
          return <XDView2 data={data} />;
        case "swipeableDrawer":
          return <SwipeableDrawer data={data} />;
        case "legends":
          return <CustomLegends data={data} />;
        case "number-grid":
          return <NumberGrid data={data} />;
        case "performanceKPIcards":
          return <PerformanceKPICards data={data} />;
        case "horizontalLine":
          return <CustomHorizontalLine data={data} />;
        case "horizontalLineWithLabel":
          return <CustomHorizontalLineWithLabel data={data} />;
        case "verticalLine":
          return <CustomVerticalLine data={data} />;
        case "chip":
          return <CustomBadge data={data} />;
        case "performanceGrowthKPICard":
          return <PerformanceGrowthKPICard data={data} />;
        case "performanceIndicatorKPICard":
          return <PerformanceIndicatorKPICard data={data} />;
        case "performanceDisplayKPICard":
          return <CustomPerformanceDisplayKPICard data={data} />;
        case "chatWindow":
          return <ChatWindow data={data} />;
        case "customModuleKPICard":
          return <CustomModuleKPICard data={data} />;
        case "customInvConfigCard":
          return <CustomInvConfigCard data={data} />;
        case "customInvConfigKpiCard":
          return <CustomInvConfigKpiCard data={data} />;
        case "multiLevelStepper":
          return <CustomMultiLevelStepper data={data} />;
        case "multiLevelStepperV2":
          return <CustomMultiLevelStepperV2 data={data} />;
        case "multiLevelModuleStepper":
          return <CustomMultiLevelModuleStepper data={data} />;
        case "customPlanConfigCard":
          return <CustomPlanConfigCard data={data} />;
        case "customColorPicker":
          return <CustomColorPicker data={data} />;
        case "displaygif":
          return <Displaygif data={data} />;
        case "html":
          return <HtmlContentDisplay data={data} />;
        case "image-upload":
          return <ImageUpload data={data} />;
        case "drag-n-drop":
          return <DragNDrop data={data} />;
        case "commentBox":
          return <CommentBox data={data} />;
        case "moodBoard":
          return <MoodBoard data={data} />;
        case "accordion-legacy":
          return <CustomAccordion1 data={data} />;
        case "forecastKPI":
          return <ForecastKpiCard data={data} />;
        case "datePicker":
          return data?.uniqueKey === "legacy" ? (
            <CustomDatePicker1
              label={data?.label}
              labeldirection={data?.labeldirection}
              values={data?.values}
              width={data?.width}
              fieldWidth={data?.fieldWidth}
              isMandatory={data?.isMandatory}
              data={data}
            />
          ) : (
            <CustomDatePicker data={data} onChange={() => {}} />
          );
        case "IATable":
          return <IATable data={data?.info} />;
        case "SelectedRowText":
          return <SelectedRowText2 data={data} />;
        case "IAInfoPanel":
          return <IAInfoPanel data={data} />;
        case "iconToggle":
          return <IconToggle data={data} />;
        case "IAFilter":
          return <IAFilter data={data} />;
        case "nestedDropdown":
          return <NestedDropdown data={data} />;
        case "customAssortDataCard":
          return <CustomAssortDataCard data={data} />;
        case "tag":
          return <CustomTag data={data} />;
        case "customPlansmartKPICard":
          return <CustomPlansmartKPICard data={data} />;
        case "recurringFreqCalendar":
          return <RecurringFreqCalendar data={data} />;
        case "switch":
          return <CustomSwitch data={data} />;
        case "temporalProvider":
          return <TemporalProvider data={data} />;
        case "figmaView":
          return <FigmaView data={data} />;
        case "archerField":
          return <ArcherField data={data} />;
        case "drop-select-table":
          return <CustomDropSelectTable data={data} />;
        case "dynamic-table":
          return <CustomDynamicTable data={data} />;
        case "custom-date-picker":
          return <CustomisedDatePicker label={data.label} />;
        case "expandableKPICard":
          return <ExpandableKpiCard data={data} />;
        case "ia-counter":
          return <Counter data={data} />;
        case "custom-columns-tool-panel":
          return <CustomColumnsToolPanel data={data} />;
        case "custom-metrics-tool-panel":
          return <CustomMetricsToolPanel data={data} />;
        case "custom-popover":
          return <CustomPopover data={data} />;
        case "breadCrumbs":
          return <BreadCrumbs data={data} />;
        case "week-range-picker":
          return <WeekRangePicker data={data} />;
        default:
          return "";
      }
    }
  };

  const getMultipleDependence = (obj, dependentInfo) => {
    switch (obj?.dependencyType) {
      case "some": {
        return obj.dependentOn.some((key) => dependentInfo[key] == true);
      }
      default: {
        return obj.dependentOn.every((key) => dependentInfo[key] == true);
      }
    }
  };
  const handleAlertClose = () => {
    dispatch({
      type: "DISPLAY_MESSAGE",
      payload: {
        showAlert: false,
        message: "",
        variant: "warning",
      },
    });
  };

  return props?.spread ? (
    <>
      {pageObject.components &&
        componentsList.map((item, index) => {
          let dependence = true;
          const dependentOn = item["dependentOn"];
          if (dependentOn) {
            dependence = Array.isArray(dependentOn)
              ? getMultipleDependence(item, dependentInfo)
              : dependentInfo[dependentOn];
            dependence = item["invertDependence"] ? !dependence : dependence;
          }
          return item.type !== "container" ? (
            <>
              {dependence && (
                <div
                  key={item.type}
                  className={
                    "impact-container-resolver  impact-subContainer-resolver" +
                    item.type
                  }
                  style={{ marginBottom: "10px", ...item?.wrapperStyle }}
                >
                  {/* <p>testing1</p> */}
                  {item.hasOwnProperty("flexComponent") && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: item.flexComponent.flex,
                      }}
                      className={"impact-flexComponent-" + item.type}
                    >
                      {item.flexComponent.components.map((thisComp, index) => {
                        return (
                          <>
                            <div>
                              {renderView({
                                key: index,
                                type: thisComp.type,
                                data: thisComp,
                              })}
                            </div>
                            <div style={{ marginRight: "1rem" }} />
                          </>
                        );
                      })}
                    </div>
                  )}
                  {dependence ? (
                    <>
                      {item["rootDependentName"] ? (
                        <>
                          {dependentInfo[`${item.rootDependentName}`] ? (
                            <>
                              {renderView({
                                key: index,
                                type: item.type,
                                data: item,
                              })}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {renderView({
                            key: index,
                            type: item.type,
                            data: item,
                          })}
                        </>
                      )}
                    </>
                  ) : null}

                  {/* <div className="content-spacer"></div> */}
                </div>
              )}
            </>
          ) : (
            <>
              {item.hasOwnProperty("flexComponent") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: item.flexComponent.flex,
                    gap: item.flexComponent.gap,
                  }}
                  className={"impact-flexComponent-" + item.type}
                >
                  {item.flexComponent.components.map((thisComp, index) => {
                    return (
                      <>
                        <div>
                          {renderView({
                            key: index,
                            type: thisComp.type,
                            data: thisComp,
                            // width: thisComp.width
                          })}
                        </div>
                        <div style={{ marginRight: "1rem" }} />
                      </>
                    );
                  })}
                </div>
              )}
              {dependence ? (
                <>
                  {item["rootDependentName"] ? (
                    <>
                      {dependentInfo[`${item.rootDependentName}`] ? (
                        <>
                          {renderView({
                            key: index,
                            type: item.type,
                            data: item,
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {renderView({
                        key: index,
                        type: item.type,
                        data: item,
                      })}
                    </>
                  )}
                </>
              ) : null}
            </>
          );
        })}
    </>
  ) : (
    <>
      {displayMessage?.showAlert && (
        <SnackBar
          message={displayMessage?.message}
          variant={displayMessage?.variant}
          handleClose={handleAlertClose}
        />
      )}
      <section className="impact-component-resolver">
        {Object.keys(pageObject).length > 0 && pageObject["pageTitle"] ? (
          <h3 style={{ fontWeight: 500, marginBottom: "16px" }}>
            {pageObject.pageTitle}
          </h3>
        ) : null}
        {pageObject.components &&
          componentsList.map((item, index) => {
            let dependence = true;
            const dependentOn = item["dependentOn"];
            if (dependentOn) {
              dependence = Array.isArray(dependentOn)
                ? getMultipleDependence(item, dependentInfo)
                : dependentInfo[dependentOn];
              dependence = item["invertDependence"] ? !dependence : dependence;
            }
            return (
              <div
                key={item.type}
                className={
                  "impact-container-resolver  impact-subContainer-resolver" +
                  item.type
                }
              >
                {item.hasOwnProperty("flexComponent") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: item.flexComponent.flex,
                      gap: item.flexComponent.gap,
                    }}
                    className={"impact-flexComponent-" + item.type}
                  >
                    {item.flexComponent.components.map((thisComp, index) => {
                      return (
                        <>
                          {renderView({
                            key: index,
                            type: thisComp.type,
                            data: thisComp,
                          })}
                        </>
                      );
                    })}
                  </div>
                )}
                {dependence ? (
                  <>
                    {item["rootDependentName"] ? (
                      <>
                        {dependentInfo[`${item.rootDependentName}`] ? (
                          <>
                            {renderView({
                              key: index,
                              type: item.type,
                              data: item,
                            })}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {renderView({
                          key: index,
                          type: item.type,
                          data: item,
                        })}
                      </>
                    )}
                  </>
                ) : null}

                {/* <div className="content-spacer"></div> */}
              </div>
            );
          })}
      </section>
    </>
  );
};

export default ComponentResolver;
