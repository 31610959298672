
// function which helps you save value in local storage
export function setStorage(key, value) {
    try {
        const lsValue = JSON.stringify(value);
        localStorage.setItem(key, lsValue);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
    }
}

// function which helps you fetch value from local storage
export function getStorage(key) {
    const lsValue = localStorage.getItem(key);
    if (!lsValue) return [];
    try {
        const data = JSON.parse(lsValue);
        return data;
    } catch (error) {
        console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
}

// function which helps you remove value from local storage
export function removeStorage(key) {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
    }
}