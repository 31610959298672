import React from 'react';
import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';

// Impact Ui
import { Alerts } from "impact-ui";

const SnackBar = (props) => {
  const { message, variant="success", handleClose ,anchorOrigin } = props; //={ vertical: 'bottom', horizontal: 'left' }
  
  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        {/* <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
          {message}
        </Alert> */}
        <Alerts variant={variant} message={message} onClose={handleClose}/>
    </Snackbar>
    
  );
};

export default SnackBar;
